/* eslint-disable react/destructuring-assignment */
/* eslint-disable fp/no-let */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TickIcon from 'Component/TickIcon/TickIcon.component';

/** @namespace Inov8/Component/ProductListBulletPoints/Component */
export class ProductListBulletPointsComponent extends PureComponent {
    static propTypes = {
        product: PropTypes.object,
    };

    render() {
        const { product } = this.props;

        if (!product?.getProductBulletPoints) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        if (!product.getProductBulletPoints.bullet_points) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let listerBulletPoints;

        try {
            listerBulletPoints = JSON.parse(product.getProductBulletPoints.bullet_points);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to parse product lister bullet points JSON:', this.props.product.sku);

            return <div block="ProductCard" elem="FeaturesList" />;
        }

        if (!listerBulletPoints) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        return (
            <div block="ProductCard" elem="FeaturesList">
                { listerBulletPoints.map((bulletPoint) => (
                    <div block="ProductCard" elem="FeaturesListItem" key={ bulletPoint.id }>
                        <TickIcon />
                        <span>{ bulletPoint.description }</span>
                    </div>
                )) }
            </div>
        );
    }
}
export default ProductListBulletPointsComponent;
