/* eslint-disable react/forbid-prop-types */
import DataContainer from '@scandipwa/scandipwa/src/util/Request/DataContainer';
import PropTypes from 'prop-types';

import RelativeSizeInfoComponent from './ProductListBulletPoints.component';

/** @namespace Inov8/Component/ProductListBulletPoints/Container */
export class ProductListBulletPointsContainer extends DataContainer {
    static propTypes = {
        product: PropTypes.object,
    };

    render() {
        const { product } = this.props;

        return (
            <RelativeSizeInfoComponent product={ product } />
        );
    }
}

export default ProductListBulletPointsContainer;
